/* eslint-disable max-lines */

import React, {Fragment} from 'react';
import {Row, Col} from 'jsxstyle';

import {BLUE, LIGHT_GREY, WHITE, ORANGE, GREY} from '@styles/colors';
import Button from '@components/Button';
import Arrow from '@components/icons/Arrow';
import Link from '@components/Link';
import Headline from '@components/Headline';
import Paragraph from '@components/Paragraph';

import {stringToUrl, getFloorFromUnit} from '@util';

const mediaQueries = {
  sm: 'screen and (max-width: 1024px)',
  lg: 'screen and (min-width: 1280px)',
};

type Unit = {
  price: string,
  floorplan: Object,
  property: Object,
};

export default ({unit}: Object<Unit>) => {
  const {price, floorplan, property}: Unit = unit;
  return (
    <Row
      height="70px"
      background={LIGHT_GREY}
      justifyContent="space-between"
      mediaQueries={mediaQueries}
      smFlexDirection="column"
      smHeight="auto"
    >
      <Col
        background={ORANGE}
        justifyContent="center"
        textTransform="capitalize"
        color={WHITE}
        padding="0 80px"
        mediaQueries={mediaQueries}
        smHeight="60px"
        smAlignItems="center"
      >
        <Headline
          fontSize="1.3rem"
          color={WHITE}
          margin="0"
        >{`$${price}.00/month`}</Headline>
      </Col>
      <Row
        width="30%"
        justifyContent="space-between"
        mediaQueries={mediaQueries}
        smWidth="100%"
        smMargin="0 auto"
        smJustifyContent="space-around"
        smHeight="80px"
      >
        <Col
          justifyContent="space-around"
          color={GREY}
          padding="10px 0"
          textTransform="capitalize"
        >
          <Paragraph textAlign="left">{`${floorplan.beds} bedroom`}</Paragraph>
          <Paragraph textAlign="left">{`${
            floorplan.baths
          } bathroom`}</Paragraph>
        </Col>
        <Col
          justifyContent="space-around"
          color={GREY}
          padding="10px 0"
          textTransform="capitalize"
          textAlign="left"
        >
          <Paragraph textAlign="left">{getFloorFromUnit(floorplan)}</Paragraph>
        </Col>
      </Row>
      <Row
        alignItems="center"
        color={GREY}
        mediaQueries={mediaQueries}
        smHeight="60px"
        justifyContent="center"
      >
        {property.address.neighborhood && (
          <Fragment>
            <Link
              href={`/neighborhoods/${stringToUrl(
                unit.property.address.neighborhood[0].name,
              )}`}
              style={{
                color: BLUE,
                textTransform: 'capitalize',
                textDecoration: 'none',
                marginRight: '10px',
                fontSize: '1.2rem',
              }}
            >
              {unit.property.address.neighborhood[0].name}
            </Link>
            <Arrow />
          </Fragment>
        )}
      </Row>
      <Row mediaQueries={mediaQueries} smDisplay="none">
        <Button
          buttonLink={floorplan?.document?.url}
          buttonText="download floor plan PDF"
          buttonColor={BLUE}
          buttonBorder="none"
          buttonHeight="100%"
          buttonWidth="250px"
          copyColor={WHITE}
        />
      </Row>
    </Row>
  );
};
