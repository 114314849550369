/* eslint-disable max-lines */

import React, {Component, Fragment} from 'react';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';

import Layout from '@layouts';
import Hero from '@components/Hero';
import PreFooter from '@components/PreFooter';
import Section from '@components/layout/Section';
import ImageCallout from '@components/ImageCallout';
import CardGrid from '@components/CardGrid';
import FloorPlanCard from '@components/FloorPlanCard';
import UnitDescriptionBar from '@components/UnitDescriptionBar';
import Headline from '@components/Headline';
import Paragraph from '@components/Paragraph';
import {BLUE, WHITE, ORANGE} from '@styles/colors';
import Link from '@components/Link';
import {addressToGoogleFriendlyAddress, objectToString, flatten} from '@util';
import getAllAvailableUnits from '@util/getAllAvailableUnits';

import type {UnitType, FloorplanType} from '@types-local';

type Props = {
  data: {
    wagtail: {
      ...UnitType,
      ...FloorplanType[],
    },
  },
};

type State = {
  availableUnits: UnitType[] | null,
  randomSeed: number | null,
  unitSalesforceId: number | null,
};

function moreAvailableUnitsExist(
  floorplans: FloorplanType[],
  currentUnitSalesforceId: number,
  status: boolean,
): boolean | UnitType[] {
  if (floorplans) {
    if (Array.isArray(floorplans) && floorplans.length > 0) {
      const availableFloorplans = floorplans.filter(floorplan => {
        if (floorplan && floorplan.property) {
          return !floorplan.property.soldout;
        } else return null;
      });
      const availableUnits = availableFloorplans
        .map(floorplan => {
          if (floorplan.units) {
            const {units} = floorplan;
            const filteredUnits = units
              .filter(unit => unit.available)
              .filter(unit => unit.salesforceId !== currentUnitSalesforceId);
            return filteredUnits;
          } else return null;
        })
        .reduce(flatten, []);
      if (status) {
        return availableUnits.length > 0 ? true : false;
      }
      return availableUnits;
    }
  }
}

function generateUnitMeta(
  unit: UnitType | null,
): {metaTitle: string, metaDescription: string} {
  if (!unit) return;
  const {floorplan, property} = unit;
  const {baths, beds, squareFeet, description} = floorplan;
  const {name: propertyName} = property;
  return {
    metaTitle: `AVAILABLE ${beds} br, ${baths} ba, ${squareFeet} Sq Ft Apartment | ${propertyName}`,
    metaDescription: `Explore this ${squareFeet} Sq Ft luxury apartment with ${beds} br ${baths} ba featuring ${description}.`,
  };
}

export default class Unit extends Component<Props, State> {
  constructor(props) {
    super(props);
    const {data} = this.props;
    const {unit, floorplans} = data.wagtail;
    const randomSeed = 0.5 - Math.random();
    this.state = {
      availableUnits: getAllAvailableUnits(floorplans, randomSeed),
      unitMeta: generateUnitMeta(unit),
      unitSalesforceId: unit.salesforceId,
      otherAvailableUnits: null,
    };
  }

  componentDidMount() {
    if (!this.state.otherAvailableUnits) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState(prev => ({
        otherAvailableUnits: prev.availableUnits.filter(
          unit => unit?.salesforceId !== prev?.unitSalesforceId,
        ),
      }));
    }
  }

  render() {
    const {data} = this.props;
    const {fluid: fluidHeroImage} = data.allFile?.edges[0].node.childImageSharp;
    const {title, meta} = data.site.siteMetadata;
    const {unit, floorplans} = data.wagtail;
    const {floorplan, property} = unit;
    const {metaTitle, metaDescription} = this.state.unitMeta;

    return (
      <Fragment>
        <Helmet>
          <html lang="en" />
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <Layout>
          <Hero
            fluid={fluidHeroImage}
            padding="250px 0 0 0"
            height="fit-content"
            margin="0"
          />
          <UnitDescriptionBar unit={unit} />
          <Section>
            <ImageCallout
              eyebrow={property.name}
              headline={floorplan.name}
              background={WHITE}
              alignItems="flex-start"
              textAlign="left"
              imageSrc={floorplan?.image?.url}
              imgMaxHeight="600px"
            >
              <Link
                href={`https://www.google.com/maps/search/?api=1&query=${addressToGoogleFriendlyAddress(
                  objectToString(data.wagtail.unit.property.address),
                )}`}
                style={{
                  color: BLUE,
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                }}
              >
                {objectToString(property.address)}
              </Link>
              <Link
                href={`tel: ${property.phoneNumber}`}
                style={{
                  color: BLUE,
                  fontWeight: 'bold',
                  marginBottom: '40px',
                }}
              >
                {property.phoneNumber}
              </Link>
              <Headline
                headlineType="h4"
                fontWeight="bold"
                fontSize="1.2rem"
                textTransform="uppercase"
                color={ORANGE}
                marginBottom="40px"
              >
                features
              </Headline>
              <Paragraph color={BLUE} textAlign="left">
                {floorplan.description}
              </Paragraph>
            </ImageCallout>
          </Section>
          <Section>
            <Headline color={BLUE} textAlign="center">
              More Available Apartments
            </Headline>
          </Section>
          {moreAvailableUnitsExist(
            floorplans,
            this.state.unitSalesforceId,
            true,
          ) && (
            <Section>
              <CardGrid
                data={this.state.otherAvailableUnits}
                maxWidth="1360px"
                gridItem={FloorPlanCard}
                gridTemplateColumns={'repeat(auto-fit, minmax(300px, 1fr))'}
                gridGap="40px"
                margin="0 auto"
                padding="0 20px"
              />
            </Section>
          )}
          <PreFooter
            headline="Interested in becoming a resident?"
            copy="Get in touch with us to find your perfect Fetner apartment."
          />
        </Layout>
      </Fragment>
    );
  }
}

export const query = graphql`
  query($salesforceId: String!) {
    wagtail {
      unit(salesforceId: $salesforceId) {
        id
        slug
        price
        available
        availableDate
        salesforceId
        property {
          name
          phoneNumber
          address {
            street
            city
            state
            postalCode
            neighborhood {
              name
            }
          }
        }
        floorplan {
          id
          name
          beds
          baths
          squareFeet
          salesforceId
          isPenthouse
          description
          document {
            url
          }
          image {
            url
          }
        }
      }
      floorplans {
        name
        beds
        baths
        squareFeet
        property {
          soldout
          name
          address {
            neighborhood {
              name
            }
          }
        }
        image {
          url
        }
        units {
          id
          salesforceId
          name
          slug
          price
          available
          availableDate
        }
      }
    }
    site {
      siteMetadata {
        title
        meta
      }
    }
    allFile(filter: {sourceInstanceName: {eq: "data"}, ext: {eq: ".png"}, name: {eq: "Unit_Hero"}, childImageSharp: {}}) {
      edges {
        node {
          id
          absolutePath
          childImageSharp {
            id
            fluid(quality: 100){
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
  }
`;
