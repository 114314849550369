// @flow

import React, {Component} from 'react';
import {Block, Col} from 'jsxstyle';

import Link from '@components/Link';
import {ORANGE, BLUE, LIGHT_GREY} from '@styles/colors';
import CardSpan from '@components/CardSpan';
import {stringToUrl, getFloorplanName} from '@util';

type Props = {
  id: number,
  baths: number,
  beds: number,
  name: string,
  propertyName: string,
  floor?: string,
  id?: string | number,
  neighborhood?: string,
  price?: number,
  property?: Object,
  slug?: string,
  itemBackground?: string,
  image?: {
    url: string,
  },
};

type State = {
  hovered: boolean,
};

export default class FloorPlanCard extends Component<Props, State> {
  state = {
    hovered: false,
  };

  handleMouseEnter = () => {
    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  render() {
    let {
      baths,
      beds,
      name,
      propertyName,
      image,
      id,
      ...otherProps
    } = this.props;
    name = getFloorplanName(name)
    return (
      <Link
        href={`/properties/${stringToUrl(propertyName)}${
          otherProps.slug
            ? `/unit/${otherProps.slug}/`
            : `/floorplan/${String(id)}/`
        }`}
        style={{
          width: '310px',
          alignSelf: 'center',
          justifySelf: 'center',
          textDecoration: 'none',
          background: otherProps.itemBackground
            ? otherProps.itemBackground
            : LIGHT_GREY,
          boxShadow: this.state.hovered
            ? '0 10px 50px 0 rgba(45, 50, 52, 0.25)'
            : 'none',
          transition: 'box-shadow 0.4s',
        }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onFocus={this.handleMouseEnter}
        onBlur={this.handleMouseLeave}
      >
        <Block
          component="img"
          width="90%"
          margin="0 auto"
          padding="36px 0"
          height="245px"
          objectFit="contain"
          props={{
            src: image && image.url && image.url,
            alt: `Learn more about ${name}`,
          }}
        />
        <Col
          backgroundColor={this.state.hovered ? ORANGE : BLUE}
          padding={'10px 15px'}
          transition="background 0.4s"
        >
          <CardSpan text={`${propertyName}, ${name}`} margin="0 0 3px 0" />
          <CardSpan
            text={`${beds} bedroom | ${baths} bath`}
            margin="0"
            fontWeight="initial"
          />
          {otherProps.price && (
            <CardSpan
              text={`$${otherProps.price}/month`}
              margin="5px 0 0 0"
              fontWeight="initial"
            />
          )}
        </Col>
      </Link>
    );
  }
}
