// @flow

import React from 'react';
import {Block} from 'jsxstyle';
import {WHITE} from '@styles/colors';

type Props = {
  text: string,
  color?: string,
  fontWeight?: string,
  fontSize?: string,
  textTransform?: string,
  margin?: string,
};

export default ({
  text,
  color,
  fontWeight,
  fontSize,
  textTransform,
  margin,
}: Props) => (
  <Block
    component="span"
    color={color ? color : WHITE}
    textTransform={textTransform ? textTransform : 'capitalize'}
    fontWeight={fontWeight ? fontWeight : 'bold'}
    fontSize={fontSize ? fontSize : '1rem'}
    lineHeight="21px"
    margin={margin ? margin : '10px 0'}
  >
    {text}
  </Block>
);
